.meals {
    max-width: 60rem;
    width: 90%;
    margin: 2rem auto;
    animation: meals-appear 1s ease-out forwards;
}

.MealsLoading {
    text-align: center;
    padding-top: 2rem;
    color: white;
    font-size: 24pt;
    font-weight: bold;
}

.MealsError {
    text-align: center;
    padding-top: 2rem;
    color: red;
    font-size: 24pt;
    font-weight: bold;
}

.meals ul {
    list-style: none;
    margin: 0;
    padding: 0;
}



@keyframes meals-appear {
    from {
        opacity: 0;
        transform: translateY(3rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}